export const data = {
  title: { name: "LWONG CONSULTING", func: null },
  tabs: [
    { name: "Contact", func: () => console.log("hi") },
    { name: "Careers", func: () => console.log("hi") },
    { name: "Projects", func: () => console.log("hi") },
    // { name: "People", func: () => console.log("hi") },
    { name: "About", func: () => console.log("hi") },
  ],
};
